import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login.vue';

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login
    },
    {
      path: '/login',
      component: Login
    },

    {
      path: '/resultados',
      name: 'resultados',
      // lazy-loaded
      component: () => import('./views/Resultados.vue')
    },


    {
      path: '/inicio',
      name: 'inicio',
      // lazy-loaded
      component: () => import('./views/Inicio.vue')
    },

  ]
});
