<template>
  <div id="app"></div>
</template>

<script>
import User from '../models/user';

export default {
  name: 'Login',
  data() {
    return {
      user: new User('', ''),
      loading: false,
      message: ''
    };
  },
  computed: {
    //loggedIn() {
    //  return this.$store.state.auth.status.loggedIn;
    //}
  },
  created() {
    //if (this.loggedIn) {
    //this.$router.push('/inicio');
    this.$router.push('/resultados');
    //}
  },
  mounted() {
    //if (this.loggedIn) {
    //this.$router.push('/inicio');
    this.$router.push('/resultados');
    //}
  },
  methods: {
    handleLogin() {
      console.log('Login');
      this.loading = true;
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            () => {
              //this.$router.push('/inicio');
              this.$router.push('/resultados');
            },
            error => {
              this.loading = false;
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
        }
      });
    }
  }
};
</script>

<style scoped>
.texto-logo {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #ffffff;
  line-height: 1em;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  overflow-wrap: break-word;
  font-weight: 300;
  font-size: 20px;
}
.image-logo {
  text-align: center;
}

.texto-pie {
  text-align: center;
  margin-left: auto;
  margin-top: auto;
  margin-right: auto;
  text-align: center;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
}
label {
  display: block;
  margin-top: 10px;
}
.btn-login {
  border-radius: 16px;
  border-style: solid;
  border-width: medium;
  padding: 12px 35px 12px 35px;
  font-weight: bold;
  color: #8caf36;
  border-color: #8caf36;
  background-color: #ffffff;
}
.btn-login:hover {
  border-radius: 16px;
  border-style: solid;
  border-width: medium;
  padding: 12px 35px 12px 35px;
  font-weight: bold;
  color: #ffffff;
  border-color: #8caf36;
  background-color: #8caf36;
}
.texfield-formulario {
  background-color: #ffffff;
  border-radius: 10px;
}
input::placeholder {
  color: #bbbbbb;
  font-size: 1em;
}
.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}
.card {
  max-width: 800px;
  background-color: #ffffff;
  padding: 20px 30px 20px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 20px;
  -moz-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.profile-img-card {
  width: 80px;
  height: 80px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.nav-link {
  color: #aaaaaa;
}
.contenedor {
  margin: 0px 0px 0px 0px;
  padding: 5px 5px 5px 5px;
  position: relative;
  text-align: center;
}

@media (min-width: 320px) and (max-width: 900px) {
  .contenedor {
    margin: 0px 0px 0px 0px;
    padding: 4px 4px 4px 4px;

    text-align: center;
  }
  .texto-pie {
    font-size: 10px;
    text-align: center;
  }
  .texto-logo {
    font-size: 10px;
  }
}
</style>
